import { Component, OnInit, EventEmitter, Input, ViewChildren, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { decodedToken } from 'app/core/helpers/token.helper';
import { JwtAuthService } from 'app/core/services/auth/jwt-auth.service';
import { LayoutService } from 'app/core/services/layout.service';
import { MessagingService } from 'app/core/services/messaging.service';
import { ThemeService } from 'app/core/services/theme.service';
import { UserService } from 'app/core/services/user.service';
import { VenueService } from 'app/core/services/venue.service';
import { NotificationsComponent } from '../notifications/notifications.component';
import { NavigationService } from 'app/core/services/navigation.service';
import { NotificationService } from 'app/views/notification/service/notification.service';
import { ToastService } from 'app/core/services/toast.service';

// import { tgssNotifications2Component } from '../tgss-notifications2/tgss-notifications2.component';
import { VenuenotificationService } from '../../../venueowner/venue-notification/venuenotification.service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  @ViewChildren(NotificationsComponent) noti;
  public availableLangs = [{
    name: 'EN',
    code: 'en',
    flag: 'flag-icon-us'
  }, {
    name: 'ES',
    code: 'es',
    flag: 'flag-icon-es'
  }];
  currentLang = this.availableLangs[0];

  public tgssThemes;
  public layoutConf: any;
  userDetails: any;
  @Output('emitList') public emitList = new EventEmitter<any>()
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public jwtAuth: JwtAuthService,
    private router: Router,
    private userservice: UserService,
    private venueService: VenueService,
    private messagingService: MessagingService,
    private navservice: NavigationService,
    private notificationService: NotificationService,
    private venueNotifiService: VenuenotificationService,
    private toaster: ToastService
  ) { }
  ngOnInit() {
    const token = window.localStorage.getItem("CLUBINNTOKEN");
    this.messagingService.requestPermission(token);
    this.messagingService.receiveMessage();
    this.tgssThemes = this.themeService.tgssThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);
    this.userDetails = decodedToken();
    this.notificationCount();
  }

  ngAfterViewInit(): void {
    if (window.localStorage.getItem("CLUBINNTOKEN") != null) {
      this.getAllNotification();
      this.notificationCount();
    }
  }

  emitValue() {
    this.getAllNotification()
    setTimeout(() => {
      this.emitList.emit(this.notificationList)
    }, 1000);
  }

  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, { transitionClass: true });
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, { transitionClass: true });

  }

  onSearch(e) {
    //   console.log(e)
  }
  public notificationList: [];
  getAllNotification() {
    let token: any = decodedToken();
    let formdata = new FormData();
    if (token?.portal == 'admin') {
    
      formdata.append('portal', token?.portal);
      formdata.append('venue_owner_id', token?.venue_owner_id ? token?.venue_owner_id : '');
      console.log(token?.portal, 'token?.portal');
    
      this.notificationService.notifiGetCall(formdata).subscribe((res) => {
        if (res?.keyword == "success") {
          let data = res?.data;
          let readmsg = data.filter((ele) => ele.msg_read == 0);
          this.notificationList = readmsg;
          this.navservice.notificationCount.next(readmsg.length)
          this.notificationCount();
        } else {
        
        }

      }, (err) => {
        if (err.status == 401) {
          this.toaster.showErrorToast("Session Expired..! Please Login Again...!");
        }
      })
    } else {
      this.venueNotifiService.notifiGetCall(formdata).subscribe((res) => {
        if (res?.keyword == "success") {
          let data = res?.data;
          let readmsg = data.filter((ele) => ele.msg_read == 0);
          this.notificationList = readmsg;
          this.navservice.notificationCount.next(readmsg.length)
          this.notificationCount();
        } else {

        }

      }, (err) => {
        if (err.status == 401) {
          this.toaster.showErrorToast("Session Expired..! Please Login Again...!");
        }
      })
    }
  }
  

  public count: number = 0;
  notificationCount() {
    this.navservice.getCount().subscribe((res: any) => {
      this.count = res;
    });
  }

  signout() {
    let token = decodedToken();
    this.messagingService.onLogout().subscribe();
    if (token.portal == 'venue owner') {
      localStorage.clear();
      this.venueService.logout();
      // signout
    }
    else {
      localStorage.clear();
      this.userservice.logout();
    }
  }
}
