import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { decodedToken } from 'app/core/helpers/token.helper';
import { NotificationService } from '../../../views/notification/service/notification.service';
import { ToastService } from 'app/core/services/toast.service';
import * as moment from 'moment';
import { EncrptDecrptService } from 'app/core/services/encrpt-decrpt.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { MessagingService } from 'app/core/services/messaging.service';
import { SharedService } from 'app/core/services/shared.service';
import { Subscription } from 'rxjs';
import { VenuenotificationService } from 'app/venueowner/venue-notification/venuenotification.service';

export interface List {
  notification_id: number,
  title: string,
  body: string,
  user_type: number,
  page: string,
  portal: string,
  data: {
    customer_name: string,
    customer_id: number,
    customer_code: string
  },
  msg_read: number,
  created_on: string
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;

  // Dummy notifications
  notificationss = [{
    message: 'New contact added',
    icon: 'assignment_ind',
    time: '1 min ago',
    route: '/inbox',
    color: 'primary'
  }, {
    message: 'New message',
    icon: 'chat',
    time: '4 min ago',
    route: '/chat',
    color: 'accent'
  }, {
    message: 'Server rebooted',
    icon: 'settings_backup_restore',
    time: '12 min ago',
    route: '/charts',
    color: 'warn'
  }]


  @Input() notifications: List[] = [];
  clickSubscription: Subscription;
  constructor(private router: Router,
    private services: NotificationService,
    private venueService: VenuenotificationService,
    private toaster: ToastService,
    private encript: EncrptDecrptService,
    private navservices: NavigationService,
    private messagingService: MessagingService,
    private sharedService: SharedService) {
    this.clickSubscription = this.sharedService.getClick().subscribe(() => {
      this.autoRead();
    })
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });
    this.messagingService.currentMessage.subscribe((message) => {
      console.log(message, '----------->>>>>>>>new message');
      this.notifiList();
    });
    this.notifiList();
  }

  notifiList() {
    let token: any = decodedToken();
    let formdata = new FormData();
    if (token?.portal == 'admin') {
      formdata.append('portal', token?.portal);
      formdata.append('venue_owner_id', token?.venue_owner_id ? token?.venue_owner_id : '');

      this.services.notifiGetCall(formdata).subscribe((res) => {
        if (res?.keyword == "success") {
          let data = res?.data;
          this.notifications = data.filter((resp) => resp.msg_read == 0);
          this.navservices.notificationCount.next(this.notifications.length)
        } else {
          this.notifications = [];
        }

      }, (err) => {
        if (err.status == 401) {
          this.toaster.showErrorToast("Session Expired..! Please Login Again...!");
        }

      })
    } else {
      this.venueService.notifiGetCall(formdata).subscribe((res) => {
        if (res?.keyword == "success") {
          let data = res?.data;
          this.notifications = data.filter((resp) => resp.msg_read == 0);
          this.navservices.notificationCount.next(this.notifications.length)
        } else {
          this.notifications = [];
        }

      }, (err) => {
        if (err.status == 401) {
          this.toaster.showErrorToast("Session Expired..! Please Login Again...!");
        }

      })
    }
  }

  public count: number = 0;
  clicked(element) {

    let token: any = decodedToken();
    if (token?.portal == 'admin') {
      //this.loader.open();

      let formdata = new FormData();
      let value = 'single';
      formdata.append('type', value);
      formdata.append('notification_id', element?.notification_id);
      
      if (element?.msg_read == 0) {
        this.services.markAllRead(formdata).subscribe((res) => {
          if (res?.keyword == "success") {
            //this.loader.close();
            this.notifiList();
          }

        }, (err) => {
          //this.loader.close();
          if (err.status == 401) {
            this.toaster.showErrorToast("Session Expired..! Please Login Again...!");
          }
        })
      }
      
      if (element?.page == 'Booking') {
        let obj = {
          booking_id: element?.data?.booking_id,
        };
        let encode = this.encript.encript(JSON.stringify(obj));
        this.router.navigate(["/bookings/view-booking"], { queryParams: { id: encode } });
      } else if (element?.page == 'Artist') {
        let obj = {
          artist_id: element?.data?.artist_id,
        };
        let encode = this.encript.encript(JSON.stringify(obj));
        this.router.navigate(["/artists/view"], { queryParams: { id: encode } });
      } else if (element?.page == 'Place') {
        let obj = {
          place_Id: element?.data?.place_id,
        };
        let encode = this.encript.encript(JSON.stringify(obj));
        this.router.navigate(["/places/view"], { queryParams: { id: encode } });
      } else if (element?.page == 'Ratings') {
        let obj = {
          rating_id: element?.data?.rating_id,
        };
        let encode = this.encript.encript(JSON.stringify(obj));
        this.router.navigate(["/ratings-reviews/view"], { queryParams: { id: encode } });
      }
      else if (element?.page == 'Mobile Register') {
        let obj = {
          customer_id: element?.data?.customer_id,
        };
        let encode = this.encript.encript(JSON.stringify(obj));
        this.router.navigate(["/customers/view"], { queryParams: { id: encode } });
      }
      else if (element?.page == 'Event') {
        let obj = {
          event_id: element?.data?.event_id,
        };
        let encode = this.encript.encript(JSON.stringify(obj));
        this.router.navigate(["/events/view"], { queryParams: { id: encode } });
      } else {
        this.router.navigate(["/notification"]);
      }
        
        
    } else {
      let formdata = new FormData();
      let value = 'single';
      formdata.append('type', value);
      formdata.append('notification_id', element?.notification_id);
      if (element?.msg_read == 0) {
        this.venueService.markAllRead(formdata).subscribe((res) => {
          if (res?.keyword == "success") {
            //this.loader.close();
            this.notifiList();
          }

        }, (err) => {
          //this.loader.close();
          if (err.status == 401) {
            this.toaster.showErrorToast("Session Expired..! Please Login Again...!");
          }
        })
      }
        
      if (element?.page == 'Booking') {
        let obj = {
          booking_id: element?.data?.booking_id,
        };
        console.log(obj,'venue owner obj');
        
        let encode = this.encript.encript(JSON.stringify(obj));
        this.router.navigate(["/venue-owner/bookings/view-booking"], { queryParams: { id: encode } });
      } else if (element?.page == 'Event') {
        let obj = {
          event_id: element?.data?.event_id,
        };
        let encode = this.encript.encript(JSON.stringify(obj));
        this.router.navigate(["/venue-owner/events/view"], { queryParams: { id: encode } });
      } else {
        this.router.navigate(["venue-owner/venue-notification"]);
      }
         
    }
  }


  changeTime(data: any) {
    return moment(data).fromNow();
  }
  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }

  autoRead() {
    console.log('Hitting***********************')
    this.notifiList();
  }

  // setIcon(page) {
  //   switch (page) {
  //     case 'Booking': return 'assignment_ind';
  //       break;
  //     case 'Booking': return 'assignment_ind';
  //       break
  //     case 'Booking': return 'assignment_ind';
  //       break
  //     case 'Booking': return 'assignment_ind';

  //       break
  //     case 'Booking': return 'assignment_ind';
  //       break
  //     case 'Booking': return 'assignment_ind';
  //       break
  //     default: return 'account_circle';
  //       break
  //   }
  //  }
  
  navigate() {
    let token: any = decodedToken();
    if (token.portal == 'admin') {
      this.router.navigate(['/notification'])
    } else {
      this.router.navigate(['/venue-owner/venue-notification'])
    }
  }
}
