import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from './user.service';
import { take, map } from 'rxjs/operators';
import { decodedToken } from '../helpers/token.helper';
import { VenueService } from './venue.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private venuService: VenueService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    return this.userService.isLoggedIn.pipe(take(1),
      map((isLoggedIn: boolean) => {
        let checkOTP = localStorage.getItem('ISOTPVERIFIED') ? true : false;
        let canLogin = isLoggedIn && checkOTP;
        let token = decodedToken();
        if(token.portal !='admin'){
          this.venuService.logout();
        }
        else{
          if (!canLogin) {
            // this.router.navigate(['/']); 
            this.userService.logout();
            return false;
          }
        }
        return true;
      })
    );



  }
}
