<div class="text-center dark-bg pt-16 pb-16" >
  <h6 class="m-0 text-pink">Notifications</h6>
</div>
<mat-nav-list class="notification-list" role="list">
  <!-- Notification item -->
  <mat-list-item  *ngFor="let n of notifications | slice:0:10" class="notific-item" role="listitem" 
    routerLinkActive="open" (click)="clicked(n)" >
    <!-- <mat-icon class="notific-icon mr-16">{{setIcon(n.page)}}</mat-icon>   -->
    <mat-icon class="notific-icon mr-16">question_answer</mat-icon>
    <a >

      <div class="mat-list-text" >
        <h4 class="message">{{n.title}}</h4>
        <small class="time text-muted">{{changeTime(n.created_on)}}</small>
      </div>
    </a>
  </mat-list-item>
</mat-nav-list>
<div class="notify_sec mt-16" *ngIf="notifications">
  <small><a (click)="navigate()">View More</a></small>
</div>
<div class="text-center view-more mt-16" *ngIf="notifications?.length<1"> 
  <p>No notification found.....</p> 
</div>  