import { Injectable } from "@angular/core";
import * as crypto from 'crypto-js';
import { constant } from "../helpers/global.helper";

@Injectable({
    providedIn: 'root'
})

export class EncrptDecrptService {
    keySize = 256;
    ivSize = 128;
    iterations = 1000;
    password: string;
    constructor(
    ){

    }

    encript(value){
        var password = constant().app.password;
        var salt = crypto.lib.WordArray.random(128/8);
  
        var key = crypto.PBKDF2(password, salt, {
            keySize: this.keySize/32,
            iterations: this.iterations
          });
      
        var iv = crypto.lib.WordArray.random(128/8);
        
        var encrypted = crypto.AES.encrypt(value, key, { 
          iv: iv, 
          padding: crypto.pad.Pkcs7,
          mode: crypto.mode.CBC
          
        });
        var transitmessage = salt.toString()+ iv.toString() + encrypted.toString();
        return transitmessage;
    }

    decript(value){
        var password = constant().app.password;
        var salt = crypto.enc.Hex.parse(value.substr(0, 32));
        var iv = crypto.enc.Hex.parse(value.substr(32, 32))
        var encrypted = value.substring(64);
        
        var key = crypto.PBKDF2(password, salt, {
            keySize: this.keySize/32,
            iterations: this.iterations
          });
      
        var decrypted = crypto.AES.decrypt(encrypted, key, { 
          iv: iv, 
          padding: crypto.pad.Pkcs7,
          mode: crypto.mode.CBC
          
        })
        return decrypted.toString(crypto.enc.Utf8);
    }
}